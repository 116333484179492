/**
* All classnames are set by the video-react package
* !important is used where it is required in order to override video-react's default styles 
**/

/* Control Bar Styles  */

.video-react .video-react-control-bar {
    background-color: transparent !important;
    display: flex !important;
    height: 100% !important;
    position: absolute;
    justify-content: center;
    align-items: center;
}

/* General Player Styles/Override defaults  */

.video-react .video-react-big-play-button::before {
    display: none;
}

.video-react .video-react-big-play-button {
    display: none !important;
}
